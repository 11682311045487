import axios from 'axios'

export default {
    getAccounts: () => axios.get('account/list'),
    getInfo: () => axios.get('account/general-info'),
    getPublicInfo: () => axios.get('account/general-public-info'),
    setInfo: (data) => axios.put('account/general-info', data),
    getAccountDetails: () => axios.get('account/details'),
    changeLogo: (data) => axios.post('account/logo', data, {headers: {"Content-Type": "multipart/form-data",}}),
    setPassword: (data) => axios.post('users/update-password', data),
}